import { GlobalError } from "dnb-ui-lib/components"
import Layout from "../components/layout/Layout"
import React from "react"
import SEO from "../components/seo/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <GlobalError back={false} status="404" />{" "}
  </Layout>
)

export default NotFoundPage
